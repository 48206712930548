import { Link } from "react-router-dom";

const TOS = () => {
  return (
    <main className="max-w-xl mx-auto">
      <div className="p-5">
        <Link to="/" className="btn btn-ghost">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            className="w-5 h-5"
          >
            <path
              fillRule="evenodd"
              d="M15 10a.75.75 0 01-.75.75H7.612l2.158 1.96a.75.75 0 11-1.04 1.08l-3.5-3.25a.75.75 0 010-1.08l3.5-3.25a.75.75 0 111.04 1.08L7.612 9.25h6.638A.75.75 0 0115 10z"
              clipRule="evenodd"
            />
          </svg>
          Back
        </Link>
        <h1 className="text-3xl font-extrabold pb-6">Terms and Conditions</h1>

        <pre
          className="leading-relaxed whitespace-pre-wrap"
          style={{ fontFamily: "sans-serif" }}
        >
          {`Effective Date: February 15, 2024

These Terms and Conditions ("Terms", "Terms and Conditions") govern your relationship with the Social Postr website and service (the "Service") operated by Arnaud Labs LLC ("us", "we", or "our"), located at 6770 Stanford Ranch Rd #1242, Roseville, CA 95678.

Please read these Terms and Conditions carefully before using our Service.

Your access to and use of the Service is conditioned upon your acceptance of and compliance with these Terms. These Terms apply to all visitors, users, and others who wish to access or use the Service. By accessing or using the Service, you agree to be bound by these Terms. If you disagree with any part of the terms, then you do not have permission to access the Service.

Subscriptions

Certain parts of the Service are billed on a subscription basis ("Subscription(s)"). You will be billed in advance on a recurring, periodic basis ("Billing Cycle"). Billing cycles are set either on a monthly or annual basis, depending on the type of subscription plan you select upon purchasing a Subscription.

Your Subscription will automatically renew under the same conditions unless you cancel it or Social Postr (Arnaud Labs LLC) cancels it. You can cancel your Subscription renewal through your online account management page or by contacting the Social Postr customer support team at support@arnaudlabsllc.com.

A valid payment method is required to process the payment for your Subscription. You must provide Social Postr with accurate and complete billing information including full name, address, state, zip code, telephone number, and valid payment method information. By submitting such payment information, you authorize Social Postr to charge all Subscription fees incurred through your account to any such payment instruments.

If automatic billing fails for any reason, Social Postr will issue an electronic invoice indicating that you must proceed manually, within a certain deadline, with the full payment corresponding to the billing period as indicated on the invoice.

Free Trial

Social Postr may, at its sole discretion, offer a Subscription with a free trial for a limited period of time ("Free Trial").

If you enter your billing information when signing up for the Free Trial, you will not be charged by Social Postr until the Free Trial has expired. On the last day of the Free Trial period, unless you cancelled your Subscription, you will be automatically charged the applicable Subscription fees for the type of Subscription you have selected.

Social Postr reserves the right to modify or cancel the Free Trial offer at any time
without notice.

Fee Changes

Social Postr, in its sole discretion and at any time, may modify Subscription fees. Any Subscription fee change will become effective at the end of the then-current Billing Cycle.

Social Postr will provide you with reasonable prior notice of any change in Subscription fees to allow you to terminate your Subscription before such change becomes effective.

Your continued use of the Service after a Subscription fee change comes into effect constitutes your agreement to pay the modified Subscription fee amount.

Refunds

Refund requests for Subscriptions may be considered by Social Postr on a case-by-case basis and granted at the sole discretion of Social Postr.

Content

Our Service allows you to post, link, store, share, and otherwise make available certain information, text, graphics, videos, or other material ("Content"). You are responsible for the Content that you post on the Service, including its legality, reliability, and appropriateness.

By posting Content on the Service, you grant us the right and license to use, modify, publicly perform, publicly display, reproduce, and distribute such Content on and through the Service. You retain any and all of your rights to any Content you submit, post, or display on or through the Service and you are responsible for protecting those rights. You agree that this license includes the right for us to make your Content available to other users of the Service, who may also use your Content subject to these Terms.

You represent and warrant that: (i) you own the Content or have the right to use it and grant us the rights and license as provided in these Terms, and (ii) the posting of your Content on or through the Service does not violate the privacy rights, publicity rights,
copyrights, contract rights, or any other rights of any person.

Accounts

When you create an account with us, you guarantee that the information you provide is accurate, complete, and current at all times. Inaccurate, incomplete, or obsolete information may result in the immediate termination of your account on our Service.

You are responsible for maintaining the confidentiality of your account and password, including but not limited to the restriction of access to your computer and/or account. You agree to accept responsibility for any and all activities or actions that occur under your account and/or password, whether your password is with our Service or a third-party service. You must notify us immediately upon becoming aware of any breach of security or unauthorized use of your account.

You may not use as a username the name of another person or entity or that is not lawfully available for use, a name or trademark that is subject to any rights of another person or entity other than you, without appropriate authorization, or a name that is otherwise offensive, vulgar, or obscene.

Copyright Policy

We respect the intellectual property rights of others. It is our policy to respond to any claim that Content posted on the Service infringes on someone's copyright or other intellectual property rights.

If you believe that your work has been copied in a way that constitutes copyright infringement, please report this by sending an email to support@arnaudlabsllc.com including a detailed description of the alleged infringement.

Limitation of Liability

In no event shall Social Postr, Arnaud Labs LLC, nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential, or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from your access to or use of or inability to access or use the Service; any conduct or content of any third party on the Service; any content obtained from the Service; and unauthorized access, use, or alteration of your transmissions or content, whether based on warranty, contract, tort (including negligence), or any other legal theory, whether or not we have been informed of the possibility of such damage.

Disclaimer

Your use of the Service is at your sole risk. The Service is provided on an "AS IS" and "AS AVAILABLE" basis. The Service is provided without warranties of any kind, whether express or implied, including, but not limited to, implied warranties of merchantability, fitness for a particular purpose, non-infringement, or course of performance.

Governing Law

These Terms shall be governed and construed in accordance with the laws of California, United States, without regard to its conflict of law provisions.

Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights. If any provision of these Terms is held to be invalid or unenforceable by a court, the remaining provisions of these Terms will remain in effect. These Terms constitute the entire agreement between us regarding our Service and supersede and replace any prior agreements we might have had regarding the Service.

Changes

We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material, we will provide at least 30 days' notice before any new terms taking effect. What constitutes a material change will be determined at our sole discretion.

By continuing to access or use our Service after those revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, you are no longer authorized to use the Service.

Contact Us

If you have any questions about these Terms, please contact us at
support@arnaudlabsllc.com.
`}
        </pre>
      </div>
    </main>
  );
};

export default TOS;
