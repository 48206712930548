/* eslint-disable */
import { useContext, useEffect, useState } from "react";
import { apiClient } from "../libs/api";
import toast from "react-hot-toast";
import { UserContext } from "../context";
import { useNavigate } from "react-router-dom";

const SelectFacebookPage = ({ platform }: any) => {
  const [state, setState] = useContext(UserContext);
  const [pages, setPages] = useState([]);
  const [selectedPage, setSelectedPage] = useState(null); // Step 1

  const navigate = useNavigate();
  useEffect(() => {
    // Add a class to disable scrolling when the modal is opened
    document.body.classList.add("modal-open");
    // Remove the class when the modal is closed
    return () => {
      document.body.classList.remove("modal-open");
    };
  }, []);

  useEffect(() => {
    async function getFacebookPages() {
      const code = new URLSearchParams(window.location.search).get("code");

      if (!code) {
        toast.error("Code not found");
      }

      await apiClient
        .post(`/api/${platform}/pages`, {
          code,
        })
        .then((response: any) => {
          if (!response.data.pages) {
            return;
          }

          setSelectedPage(response.data.pages[0]);
          setPages(response.data.pages);
        });
    }

    getFacebookPages();
  }, []);

  const selectFacebookPage = async () => {
    if (!selectedPage) {
      toast.error("Please select a Facebook page");
      return;
    }

    try {
      await apiClient
        .post(`/api/${platform}/callback`, {
          page: selectedPage,
        })
        .then((response) => {
          if (response.data.errors.length) {
            return window.alert(response.data.errors[0].msg);
          }

          setState({
            data: {
              name: response.data.data.user.name,
              email: response.data.data.user.email,
              accounts: response.data.data.user.accounts,
              access_token: response.data.data.user.access_token,
              refresh_token: response.data.data.user.access_token,
              posts: response.data.data.user.posts,
              renewsAt: response.data.data.user.renewsAt,
              status: response.data.data.user.status,
              variantName: response.data.data.user.variantName,
              variantId: response.data.data.user.variantId,
              customerPortal: response.data.data.user.customerPortal,
              videosDownloaded: response.data.data.user.videosDownloaded,
            },
            loading: false,
            error: null,
          });

          navigate("/dashboard");
        });
    } catch (error) {
      toast.error("Internal server error");
    }
  };

  return (
    <div className="fixed inset-0 flex justify-center items-center">
      <div className="bg-black opacity-30 backdrop-blur-sm fixed inset-0"></div>
      <div className="p-[24px] max-w-[612px] w-full bg-white border opacity-100 border-[#D0D5DD] rounded-xl relative">
        <div className="w-full flex items-center justify-between">
          <h3 className="h3 leading-[30px]">Select Facebook Page</h3>
        </div>

        <div className="space-y-[12px] py-[32px]">
          <select
            onChange={(e: any) => setSelectedPage(e.target.value)}
            className="text-[16px] leading-[24px] font-medium outline-none py-[10px] px-[16px] w-full border-1 border-gray-300 rounded-[8px] bg-gray-50"
          >
            {pages.map((item: any, index: number) => (
              <option key={index} value={item.name}>
                {item.name}
              </option>
            ))}
          </select>

          {platform === "instagram" && (
            <p className="text-gray-500 font-light text-[12px] leading-[16px]">
              Note: Your Instagram account needs to be connected to a Facebook
              page and you need to be an administrator of that Facebook page
            </p>
          )}
        </div>

        <div className="flex md:flex-row flex-col gap-4">
          <button
            onClick={() => navigate("/dashboard")}
            className="w-full py-[10px] border border-[#D0D5DD] rounded-lg text-base text-black font-semibold"
          >
            Cancel
          </button>
          <button
            onClick={() => selectFacebookPage()}
            className="w-full py-[10px] rounded-lg text-base text-white font-semibold bg-black"
          >
            Continue
          </button>
        </div>
      </div>
    </div>
  );
};

export default SelectFacebookPage;
