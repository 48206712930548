/* eslint-disable */
import { useEffect, useContext, useState } from "react";
import { UserContext } from "../context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSitemap,
  faUpload,
  faCalendar,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import facebook from "../assets/facebook.svg";
import instagram from "../assets/instagram.svg";
import youtube from "../assets/youtube.svg";
import tiktok from "../assets/tiktok.svg";
import pinterest from "../assets/pinterest.svg";
import linkedin from "../assets/linkedin.svg";
import { apiClient } from "../libs/api";

type PlatformImages = {
  [key: string]: string;
};

const platformImages: PlatformImages = {
  facebook: facebook,
  instagram: instagram,
  youtube: youtube,
  tiktok: tiktok,
  pinterest: pinterest,
  linkedin: linkedin,
};

const Information = ({ modal, setModal }: any) => {
  const [state, setState] = useContext(UserContext);
  //const [status, setStatus] = useState("");

  useEffect(() => {
    // Add a class to disable scrolling when the modal is opened
    document.body.classList.add("modal-open");
    // Remove the class when the modal is closed
    return () => {
      document.body.classList.remove("modal-open");
    };
  }, []);

  // Options for date and time formatting
  const options = {
    year: "numeric",
    month: "short",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: false,
    timeZoneName: "short",
  };

  // Convert date object to simplified format
  const date = modal.date.toLocaleString("en-US", options);

  /*useEffect(() => {
    async function getStatus() {
      try {
        const _id = modal._id;
        const response = await apiClient.post("/api/status", {
          _id,
        });
        setStatus(response.data.message);
      } catch (error) {
        console.log(error);
      }
    }

    getStatus();
  }, []);*/

  const revoke = async () => {
    try {
      const _id = modal._id;
      await apiClient
        .post("/api/post/revoke", {
          _id,
        })
        .then((response: any) => {
          setState({
            data: {
              name: response.data.data.user.name,
              email: response.data.data.user.email,
              accounts: response.data.data.user.accounts,
              access_token: response.data.data.user.access_token,
              refresh_token: response.data.data.user.access_token,
              posts: response.data.data.user.posts,
              renewsAt: response.data.data.user.renewsAt,
              status: response.data.data.user.status,
              variantName: response.data.data.user.variantName,
              variantId: response.data.data.user.variantId,
              customerPortal: response.data.data.user.customerPortal,
              videosDownloaded: response.data.data.user.videosDownloaded,
            },
            loading: false,
            error: null,
          });
        });
      setModal(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="p-3 fixed inset-0 flex justify-center items-center">
      <div
        onClick={() => setModal(false)}
        className="bg-black opacity-30 backdrop-blur-sm fixed inset-0"
      ></div>
      <div className="max-w-[600px] w-full p-3 md:space-y-8 bg-white border opacity-100 border-[#D0D5DD] rounded-xl relative">
        <div className="flex items-center justify-between">
          <h3 className="h3">Information</h3>
          <svg
            onClick={() => setModal(false)}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6 cursor-pointer"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </div>
        <div className="space-y-3">
          <div className="bg-white p-[16px] flex justify-between border border-[#D0D5DD] rounded-xl">
            <div className="flex items-center gap-3">
              <FontAwesomeIcon icon={faSitemap} color="#5cb176" size="lg" />
              <p className="font-xs font-medium text-black">Socials</p>
            </div>
            <div className="flex flex-col gap-3">
              {modal.accounts.map((item: any, index: any) => {
                const account = state?.data?.accounts.find(
                  (acc) => acc._id === item
                );

                const platformImage = platformImages[account?.platform || 0];
                return (
                  <>
                    <div className="px-[12px] py-[7px] font-xs font-medium text-blue-600 bg-blue-50 rounded-[32px]">
                      <div className="flex gap-[5px]" key={index}>
                        <img src={platformImage} width={24} />
                        <p>{account?.name}</p>
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
          </div>

          {/*<div className="bg-white p-[16px] flex justify-between border border-[#D0D5DD] rounded-xl">
            <div className="flex items-center gap-3">
              <FontAwesomeIcon icon={faUpload} color="#5cb176" size="lg" />
              <p className="font-xs font-medium text-black">Status</p>
            </div>
            <button className="px-[12px] py-[7px] font-xs font-medium text-blue-600 bg-blue-50 rounded-[32px]">
              {status}
            </button>
            </div>*/}
          <div className="bg-white p-[16px] flex justify-between border border-[#D0D5DD] rounded-xl">
            <div className="flex items-center gap-3">
              <FontAwesomeIcon icon={faCalendar} color="#5cb176" size="lg" />
              <p className="font-xs font-medium text-black">Scheduled</p>
            </div>
            <button className="px-[12px] py-[7px] font-xs font-medium text-blue-600 bg-blue-50 rounded-[32px]">
              {date}
            </button>
          </div>
        </div>
        <FontAwesomeIcon
          onClick={() => revoke()}
          className="float-right cursor-pointer"
          icon={faTrash}
          size="lg"
        />
      </div>
    </div>
  );
};

export default Information;
