/* eslint-disable */
import { useState, useEffect, useContext, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { UserContext } from "../context";
import logo from "../assets/logo.png";
import { motion } from "framer-motion";
import Sidebar from "./Sidebar";

const links = [
  {
    href: "/",
    label: "Home",
  },
  {
    href: "/resources",
    label: "Resources",
  },
  {
    href: "/pricing",
    label: "Pricing",
  },
  {
    href: "/download",
    label: "Download",
  },
  {
    href: "mailto:contact@socialpostr.com?subject=SocialPostr customer support",
    label: "Contact",
  },
];

const Navbar = () => {
  const [state, setState] = useContext(UserContext);

  const navigate = useNavigate();
  const location = useLocation();

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(false);
  }, [location]);

  return (
    <header className="border-b border-[#D0D5DD] bg-[#FCFCFD]">
      <nav
        className="container flex items-center justify-between px-[16px] lg:px-[96px] py-[32px]"
        aria-label="Global"
      >
        {/* Your logo/name on large screens */}
        <div className="flex lg:flex-1">
          <motion.div
            whileHover={{ scale: 0.95 }}
            className="flex items-center gap-2 shrink-0 cursor-pointer"
            onClick={() => (window.location.href = "/")}
            title="Social Postr hompage"
          >
            <img
              src={logo}
              alt="SocialPostr logo"
              className="w-10"
              width={40}
              height={40}
            />
            <span className="font-semibold text-base lg:text-2xl text-black">
              Social Postr
            </span>
          </motion.div>
        </div>
        {/* Burger button to open menu on mobile */}
        <Sidebar />

        {/* Your links on large screens */}
        <div className="hidden lg:flex lg:justify-center lg:gap-12 lg:items-center">
          {links.map((link) => (
            <Link
              to={link.href}
              key={link.href}
              className={`${
                location.pathname === link.href
                  ? "text-black font-medium"
                  : "text-[#667085] font-normal"
              }`}
              title={link.label}
            >
              {link.label}
            </Link>
          ))}
        </div>

        {/* CTA on large screens */}
        <div className="hidden lg:flex lg:justify-end lg:flex-1">
          <button
            onClick={() =>
              navigate(`/${state.data ? "dashboard" : "register"}`)
            }
            className="text-base font-semibold btn py-[10px] px-[18px] bg-black text-white"
          >
            {state.data ? "Dashboard" : "Get started"}
          </button>
        </div>
      </nav>
    </header>
  );
};

export default Navbar;

/* Mobile menu, show/hide based on menu state. }
      <div className={`relative z-50 ${isOpen ? "" : "hidden"}`}>
        <div
          className={`fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-[#FCFCFD] sm:max-w-sm sm:ring-1 sm:ring-neutral/10 transform origin-right transition ease-in-out duration-300`}
        >
          <div className="py-[32px] px-[16px] border-b border-[#D0D5DD] flex items-center justify-between">
            <Link
              className="flex items-center gap-2 shrink-0 "
              title="Social Postr hompage"
              to="/"
            >
              <span className="font-semibold text-base">Social Postr</span>
            </Link>
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5"
              onClick={() => setIsOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>*/

/* 
          <div className="flow-root">
            <div className="flex flex-col items-start">
              {links.map((link) => (
                <Link
                  to={link.href}
                  key={link.href}
                  className={`p-[16px] w-full border-b border-[#D0D5DD]  ${
                    location.pathname === link.href
                      ? "text-black font-medium"
                      : "text-[#667085] font-normal"
                  }`}
                  title={link.label}
                >
                  <div className="flex justify-between">
                    {link.label}
                    {location.pathname === link.href && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M19 7.1875L9.375 16.8125L5 12.4375"
                          stroke="black"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    )}
                  </div>
                </Link>
              ))}
            </div>
            <div className="flex flex-col">
              <button
                onClick={() =>
                  navigate(`/${state.data ? "dashboard" : "register"}`)
                }
                className="my-[16px] text-base font-semibold btn py-[10px] px-[18px] mx-[16px] bg-black text-white"
              >
                {state.data ? "Dashboard" : "Get started"}
              </button>
            </div>
          </div>
        </div>
      </div>*/
