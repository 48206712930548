import { useState } from "react";
import { apiClient } from "../libs/api";
import toast from "react-hot-toast";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const handleSubmit = async (e: any) => {
    e.preventDefault();

    try {
      const response = await apiClient.post("/api/auth/forgot-password", {
        email: email,
      });

      if (response.status === 200) {
        setEmail("");
        toast.success(response.data.message); // Use the message from the response
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="w-full relative overflow-hidden flex flex-col items-center justify-start text-left text-sm text-gray-900 font-text-md-medium">
      <div className="self-stretch flex flex-row items-center justify-start">
        <div className="self-stretch flex-1 flex flex-col items-center justify-between">
          <div className="self-stretch flex flex-col items-center justify-start py-0 px-8">
            <div className="max-w-[360px] w-full flex flex-col items-center justify-start gap-[32px] py-[32px]">
              <div className="self-stretch flex flex-col items-start justify-start gap-[12px] text-11xl">
                <div className="self-stretch relative leading-[38px] font-semibold">
                  Password reset
                </div>
              </div>
              <form
                onSubmit={handleSubmit}
                className="self-stretch rounded-xl flex flex-col items-center justify-start gap-[24px] text-gray-700"
              >
                <div className="self-stretch flex flex-col items-start justify-start gap-[20px]">
                  <div className="self-stretch flex flex-col items-start justify-start gap-[6px]">
                    <div className="relative leading-[20px] font-medium">
                      Email*
                    </div>
                    <input
                      type="email"
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                      placeholder="Email"
                      className="text-black self-stretch shadow-[0px_1px_2px_rgba(16,_24,_40,_0.05)] rounded-lg bg-white py-2.5 px-3.5 gap-[8px] text-base border-[1px] border-solid border-gray-300"
                    />
                  </div>
                </div>
                <div className="self-stretch flex flex-col items-start justify-start gap-[16px] text-base text-white">
                  <button className="self-stretch shadow-[0px_1px_2px_rgba(16,_24,_40,_0.05)] rounded-lg bg-black py-2.5 px-[18px] border-[1px] border-solid font-semibold">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
