/* eslint-disable */
import { useState, useRef, useContext } from "react";
import { UserContext } from "../context";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

function Home({ post, handleChange }: any) {
  const [state, _] = useContext(UserContext);
  const navigate = useNavigate();

  const inputRef: any = useRef();

  const handleDragOver = (event: any) => {
    event.preventDefault();
  };

  const handleDrop = (event: any) => {
    event.preventDefault();
    setFiles(event.dataTransfer.files);
  };

  const setFiles = (file: any) => {
    const typesImage = ["image/jpeg", "image/webp"];
    const typesVideo = ["video/mp4", "video/quicktime"];

    let files = post.files ? Array.from(post.files) : [];

    let imageCount = 0;

    for (let i = 0; i < file.length; i++) {
      if (typesImage.includes(file[i].type)) {
        imageCount++;
        handleChange("format", "image");
        files.push(file[i]);
      } else if (typesVideo.includes(file[i].type)) {
        handleChange("format", "video");
        files = [file[i]];
        break;
      } else {
        return toast.error("Invalid file type");
      }
    }

    if (imageCount > 1) {
      handleChange("format", "carousel");
    } else if (imageCount === 1) {
      handleChange("format", "image");
    }

    if (files) {
      const newFiles = [];
      Array.from(files).forEach((file) => {
        newFiles.push(file);
      });
      handleChange("files", files);
    } else {
      handleChange("files", file);
    }
  };

  return (
    <section>
      <div className="layout flex flex-col items-center pt-16 sm:pt-[124px]">
        <h1 className="text-center text-3xl sm:text-5xl font-semibold text-black">
          Post and schedule content across
          <br />
          multiple platforms in seconds.
        </h1>
        <div
          onDragOver={handleDragOver}
          onDrop={handleDrop}
          className="bg-white flex flex-col gap-4 border rounded-2xl p-[32px] mt-[64px] max-w-[800px] w-full"
        >
          <div
            className="cursor-pointer py-[80px] px-[72px] flex flex-col items-center justify-center gap-[16px] border-dashed border-2 rounded-2xl border-[#D0D5DD] bg-[#FCFCFD]"
            onClick={() => inputRef.current.click()}
          >
            <div
              className="w-[48px] h-[48px] flex justify-center items-center rounded-[12px] border border-[#D0D5DD]"
              style={{
                boxShadow:
                  "0px 2px 4px -2px rgba(16, 24, 40, 0.06), 0px 4px 8px -2px rgba(16, 24, 40, 0.10)",
              }}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="Frame">
                  <path
                    id="Vector"
                    d="M18.5637 17.36C19.9998 16.711 21 15.2651 21 13.5858C21 10.9899 19.1698 9.44677 16.897 9.44579C16.897 7.81314 15.6165 4.54688 12 4.54688C8.38346 4.54688 7.10303 7.81314 7.10303 9.44579C4.83308 9.46525 3 10.9899 3 13.5858C3 15.2651 3.99924 16.711 5.43632 17.36"
                    stroke="black"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    id="Vector_2"
                    d="M9.22852 15.2281L11.9995 12.457L14.7706 15.2281"
                    stroke="black"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    id="Line"
                    d="M12 12.457L12.0006 19.4512"
                    stroke="black"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
              </svg>
            </div>
            <p className="text-sm sm:text-base text-center font-normal text-[#667085]">
              <input
                type="file"
                onChange={(event: any) => setFiles(event.target.files)}
                hidden
                multiple={true}
                ref={inputRef}
              />
              <button className="cursor-pointer font-semibold text-black">
                Click to upload
              </button>{" "}
              or drag and drop
              <br />
              MP4, WebP or JPEG
            </p>
          </div>
          {post.files ? (
            <>
              {Array.from(post.files).map((file: any, index) => (
                <div
                  key={index}
                  className="rounded-[8px] px-[14px] bg-[#F9FAFB] shadow-sm border border-[#D0D5DD] py-[10px] text-base text-black font-medium"
                >
                  {file.name}
                </div>
              ))}
            </>
          ) : null}

          <div className="flex gap-4">
            <button
              onClick={() => handleChange("files", null)}
              className="shadow-sm rounded-[8px] w-1/2 py-[10px] text-black text-base font-semibold border border-[#D0D5DD]"
            >
              Cancel
            </button>
            <button
              onClick={() =>
                !state.data
                  ? navigate("/register")
                  : post.files.length
                  ? navigate("/post")
                  : toast.error("File not found")
              }
              className="shadow-sm rounded-[8px] w-1/2 py-[10px] bg-black text-white text-base font-semibold"
            >
              Post
            </button>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Home;
