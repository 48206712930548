/* eslint-disable */
import { useEffect } from "react";
import { apiClient } from "../libs/api";
import env from "../env.json";
import youtube from "../assets/youtube.svg";
import facebook from "../assets/facebook.svg";
import instagram from "../assets/instagram.svg";
import tiktok from "../assets/tiktok.svg";
import pinterest from "../assets/pinterest.svg";
import linkedin from "../assets/linkedin.svg";
import toast from "react-hot-toast";

const AddAccount = ({ setModal }: any) => {
  useEffect(() => {
    // Add a class to disable scrolling when the modal is opened
    document.body.classList.add("modal-open");
    // Remove the class when the modal is closed
    return () => {
      document.body.classList.remove("modal-open");
    };
  }, []);

  const signUpWithInstagram = async () => {
    await apiClient.get(`/api/instagram/oauth`).then((response) => {
      window.location.href = response.data.url;
    });
  };

  const signUpWithFacebook = async () => {
    await apiClient.get(`/api/facebook/oauth`).then((response) => {
      window.location.href = response.data.url;
    });
  };

  const signUpWithLinkedin = () => {
    window.location.href = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&state=true&client_id=${env.LINKEDIN_CLIENT_ID}&redirect_uri=${env.LINKEDIN_REDIRECT_URL}&scope=w_member_social%20openid%20profile%20email`;
  };

  const signUpWithPinterest = async () => {
    window.location.href = `https://www.pinterest.com/oauth/?client_id=${env.PINTEREST_CLIENT_ID}&redirect_uri=${env.PINTEREST_REDIRECT_URI}&response_type=code&scope=pins:write,pins:read,boards:write,boards:read,user_accounts:read,boards:read_secret`;
  };

  const signUpWithYouTube = async () => {
    await apiClient.get(`/api/youtube/oauth`).then((response) => {
      window.location.href = response.data.url;
    });
  };

  const signUpWithTikTok = async () => {
    await apiClient.get(`/api/tiktok/oauth`).then((response) => {
      window.location.href = response.data.url;
    });
  };

  return (
    <div className="p-3 fixed inset-0 flex justify-center items-center">
      <div
        onClick={() => setModal(false)}
        className="bg-black opacity-30 backdrop-blur-sm fixed inset-0"
      ></div>
      <div className="max-w-[600px] w-full p-3 md:space-y-8 bg-white border opacity-100 border-[#D0D5DD] rounded-xl relative">
        <div className="flex items-center justify-between">
          <h3 className="h3">Add Account</h3>
          <svg
            onClick={() => setModal(false)}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6 cursor-pointer"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </div>
        {/*<div className="space-y-3">
          <div className="bg-white p-[16px] flex justify-between border border-[#D0D5DD] rounded-xl">
            <div className="flex items-center gap-3">
              <img src={instagram} />
              <div>
                <p className="font-medium text-black">Instagram Business</p>
                <p className="text-sm text-[#667085]">Login with Facebook</p>
              </div>
            </div>
            <button
              onClick={() =>
                toast.error("Instagram integration is under maintenance")
              }
              className="px-[12px] py-[7px] font-xs font-medium text-[#F04438] bg-blue-50 rounded-[32px]"
            >
              Connect
            </button>
          </div>
          <div className="bg-white p-[16px] flex justify-between border border-[#D0D5DD] rounded-xl">
            <div className="flex items-center gap-3">
              <img src={facebook} />
              <div>
                <p className="font-medium text-black">Facebook Page</p>
                <p className="text-sm text-[#667085]">Login with Facebook</p>
              </div>
            </div>
            <button
              onClick={() =>
                toast.error("Facebook integration is under maintenance")
              }
              className="px-[12px] py-[7px] font-xs font-medium text-[#F04438] bg-blue-50 rounded-[32px]"
            >
              Connect
            </button>
          </div>*/}
        <div className="space-y-3">
          <div className="bg-white p-[16px] flex justify-between border border-[#D0D5DD] rounded-xl">
            <div className="flex items-center gap-3">
              <img src={instagram} />
              <div>
                <p className="font-medium text-black">Instagram Business</p>
                <p className="text-sm text-[#667085]">Login with Facebook</p>
              </div>
            </div>
            <button
              onClick={signUpWithInstagram}
              className="px-[12px] py-[7px] font-xs font-medium text-blue-600 bg-blue-50 rounded-[32px]"
            >
              Connect
            </button>
          </div>
          <div className="bg-white p-[16px] flex justify-between border border-[#D0D5DD] rounded-xl">
            <div className="flex items-center gap-3">
              <img src={facebook} />
              <div>
                <p className="font-medium text-black">Facebook Page</p>
                <p className="text-sm text-[#667085]">Login with Facebook</p>
              </div>
            </div>
            <button
              onClick={signUpWithFacebook}
              className="px-[12px] py-[7px] font-xs font-medium text-blue-600 bg-blue-50 rounded-[32px]"
            >
              Connect
            </button>
          </div>
          <div className="bg-white p-[16px] flex justify-between border border-[#D0D5DD] rounded-xl">
            <div className="flex items-center gap-3">
              <img src={linkedin} />
              <div>
                <p className="font-medium text-black">LinkedIn</p>
                <p className="text-sm text-[#667085]">Login with LinkedIn</p>
              </div>
            </div>
            <button
              onClick={signUpWithLinkedin}
              className="px-[12px] py-[7px] font-xs font-medium text-blue-600 bg-blue-50 rounded-[32px]"
            >
              Connect
            </button>
          </div>
          <div className="bg-white p-[16px] flex justify-between border border-[#D0D5DD] rounded-xl">
            <div className="flex items-center gap-3">
              <img src={pinterest} />
              <div>
                <p className="font-medium text-black">Pinterest</p>
                <p className="text-sm text-[#667085]">Login with Pinterest</p>
              </div>
            </div>
            <button
              onClick={signUpWithPinterest}
              className="px-[12px] py-[7px] font-xs font-medium text-blue-600 bg-blue-50 rounded-[32px]"
            >
              Connect
            </button>
          </div>
          <div className="bg-white p-[16px] flex justify-between border border-[#D0D5DD] rounded-xl">
            <div className="flex items-center gap-3">
              <img src={youtube} />
              <div>
                <p className="font-medium text-black">YouTube</p>
                <p className="text-sm text-[#667085]">Login with Google</p>
              </div>
            </div>
            <button
              onClick={signUpWithYouTube}
              className="px-[12px] py-[7px] font-xs font-medium text-blue-600 bg-blue-50 rounded-[32px]"
            >
              Connect
            </button>
          </div>
          <div className="bg-white p-[16px] flex justify-between border border-[#D0D5DD] rounded-xl">
            <div className="flex items-center gap-3">
              <img src={tiktok} />
              <div>
                <p className="font-medium text-black">TikTok</p>
                <p className="text-sm text-[#667085]">Login with TikTok</p>
              </div>
            </div>
            <button
              onClick={signUpWithTikTok}
              className="px-[12px] py-[7px] font-xs font-medium text-blue-600 bg-blue-50 rounded-[32px]"
            >
              Connect
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddAccount;
