import { motion } from "framer-motion";

const variants = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  closed: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
};

export const MenuItem = ({ item }: any) => {
  const style = {
    width: 40,
    height: 40,
    borderRadius: "50%",
    flex: "40px 0",
    color: "white",
    marginRight: 20,
    fontSize: 20,
  };
  return (
    <motion.li
      variants={variants}
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.95 }}
    >
      <a href={item.href} className="cursor-pointer" style={style}>
        {item.label}
      </a>
    </motion.li>
  );
};
